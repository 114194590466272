import { PA } from "app/_types/PATypes";
import ILog from "../../../_lib/global/Log";
import { Sessions } from "../../../_types/Session";

import { Directus } from "app/_lib/directus/DirectusRest";
import { DateTime } from "luxon";

export class CampaignSessionService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }

   async create(args: { token: string; payload: PA.Flows.Session.GetCampaignSession["Req"]; endpoint?: string } & NextFetchRequestConfig): Promise<Sessions.Campaign.Session> {
      const { data: res } = await Directus.post<PA.Flows.Session.GetCampaignSession["Res"]>({
         endpoint: args.endpoint ? args.endpoint : this.endpoint,
         token: args.token,
         payload: {
            campaign_id: args.payload.campaign_id,
            role_type: args.payload.role_type as PA.Flows.Session.GetCampaignSession["Req"]["role_type"]
         },
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });
      const preNormalizedSession = res as unknown as PA.Flows.Session.GetCampaignSession["Res"];

      const session = { ...preNormalizedSession, expires: DateTime.fromISO(preNormalizedSession?.expires).toMillis() };
      ILog.v("CampaignSessionService.createSession", { preNormalizedSession, session, res });
      return session;
   }
}
