import { ConfirmAttemptAPI } from "app/(api)/attempts/confirm/route";
import { CreateAttemptAPI } from "app/(api)/attempts/create/route";
import { DuplicateTemplateAPI } from "app/(api)/templates/duplicate/route";
import { Endpoints } from "app/_lib/global/Endpoints";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { BaseRest } from "app/_lib/rest/BaseRest";

// type NextServiceRequest<Req, Res, Endpoint> = T["Req"];
export class NextService {
   static async post<Res>(args: { payload: Partial<Res>; endpoint: string } & NextFetchRequestConfig) {
      const response = await BaseRest.post<Res, Partial<Res>>({
         baseURL: PublicEnv.ApplicationBaseURL!,
         endpoint: args.endpoint,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });

      return response;
   }
   static async createActivityAttempt(args: { payload: CreateAttemptAPI["Req"] } & NextFetchRequestConfig) {
      const response = await BaseRest.post<CreateAttemptAPI["Res"], CreateAttemptAPI["Req"]>({
         baseURL: PublicEnv.ApplicationBaseURL!,
         endpoint: Endpoints.API.NextWebService.CreateAttempt,
         // token: args.token,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      ILog.v("createActivityAttempt", { response });
      return response;
   }
   static async duplicateTemplate(args: { payload: DuplicateTemplateAPI["Req"] } & NextFetchRequestConfig) {
      const response = await BaseRest.post<DuplicateTemplateAPI["Res"], DuplicateTemplateAPI["Req"]>({
         baseURL: PublicEnv.ApplicationBaseURL!,
         endpoint: Endpoints.API.NextWebService.DuplicateTemplate,
         // token: args.token,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      ILog.v("duplicateTemplate", { response });
      return response;
   }
   static async confirmAttempt(args: { payload: ConfirmAttemptAPI["Req"] } & NextFetchRequestConfig) {
      const response = await BaseRest.post<ConfirmAttemptAPI["Res"], ConfirmAttemptAPI["Req"]>({
         baseURL: PublicEnv.ApplicationBaseURL!,
         endpoint: Endpoints.API.NextWebService.ConfirmCompleteAttempt,
         // token: args.token,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      ILog.v("createActivityAttempt", { response });
      return response;
   }
}
