import { Query } from "@directus/sdk";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { M } from "app/_types/Schema";
import { Sessions } from "app/_types/Session";
import { TAGS } from "../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";

export const apiCampaignSession = apiService.injectEndpoints({
   endpoints: (builder) => ({
      createCampaignSession: builder.query<
         Sessions.Campaign.CDUSession,
         {
            query: Query<M.CustomDirectusTypes, M.CampaignDirectusUser[]>;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               ILog.v("GetCDUByUserTypeCampaignId START", { arg });
               const authenticatedSession = getAuthenticatedSessionClientside();
               const token = authenticatedSession?.access_token;
               if (!token) {
                  ILog.v("GetCDUByUserTypeCampaignId NO_TOKEN");
                  throw new Error(TAGS.AUTHENTICATED_ERROR);
               }
               // const requestId = v4();
               // const ws = await WS_NAMESPACED({ roleQuery: calculateAuthenticatedRole(), requestId, dispatch: api.dispatch, tokenOverride: authenticatedSession.access_token });
               // const client = ws?.client;
               // if (!client) throw new Error("No client found");

               // ILog.v("emitWSMessage", { ws, client, requestId });
               // const res = await socketResponse<M.CampaignDirectusUser[] | null, Record<string, any>>(client, {
               //    type: "items",
               //    action: "read",
               //    collection: "campaign_directus_user",
               //    query: arg.query
               // });
               // ILog.v("emitWSMessage", { res });
               // ws.removeRequestId(requestId);
               // let campaignDirectusUser = null;
               // if (res && res.length > 0) {
               //    campaignDirectusUser = res[0];
               // } else {
               //    campaignDirectusUser = await REST.CampaignDirectusUserService.getOne({
               //       token: token,
               //       query: arg.query
               //    });
               // }

               const campaignDirectusUser = await REST.CampaignDirectusUserService.getOne({
                  token: token,
                  query: arg.query
               });

               if (!campaignDirectusUser) {
                  ILog.v("GetCDUByUserTypeCampaignId NO_CAMPAIGN_DIRECTUS_USER", { campaignDirectusUser, query: arg.query });
                  throw new Error(TAGS.CDU_ERROR);
               }
               if (typeof campaignDirectusUser.campaign_directus_user_campaign !== "string") throw new Error("campaign_directus_user_campaign is not a string");
               const session = await REST.CampaignSessionService.create({
                  token: token,
                  payload: { campaign_id: campaignDirectusUser?.campaign_directus_user_campaign as string, role_type: campaignDirectusUser.campaign_directus_user_role }
               });
               ILog.v("GetCDUByUserTypeCampaignId FINAL", { session });

               return { data: { ...campaignDirectusUser, session } };
            } catch (error: any) {
               ILog.v("GetCDUByUserTypeCampaignId ERROR", { error });
               return handleRTKError({ error, endpoint: "createCampaignSession" });
            }
         },
         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CAMPAIGN_SESSION, includeList: true }]
            })
      })
   }),

   overrideExisting: true
});
