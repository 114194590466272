import { Query } from "@directus/sdk";
import { Directus } from "app/_lib/directus/DirectusRest";
import { PrivateEnv } from "app/_lib/global/PrivateEnv";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { M } from "app/_types/Schema";
import { ActivityAttemptService } from "./activityAttemptService";
import { AssetService } from "./assetService";
import { ActivitySessionService } from "./authentication/activitySessionService";
import { CampaignSessionService } from "./authentication/campaignSessionService";
import { CampaignDirectusUserService } from "./campaignDirectusUserService";
import { CampaignService } from "./campaignService";
import { ChatMessageService } from "./chatMessageService";
import { ChatService } from "./chatService";
import { CommitmentConnectionService } from "./commitmentConnectionService";
import { DashboardControlService } from "./dashboardControl";
import { DashboardVersionService } from "./dashboardVersion";
import { DirectusNotificationsService } from "./directusNotifications";
import { DirectusUsersService } from "./directusUsersService";
import { DocumentService } from "./documentService";
import { EditionService as CampaignEditionService } from "./editionService";
import { FolderService } from "./folderService";
import { MeetingConnectionService } from "./meetingConnectionService";
import { MeetingService } from "./meetingService";
import { NextService } from "./nextApi/nextService";
import { SectionAssetService } from "./sectionAssetService";
import { SectionDocumentEditService } from "./sectionDocumentEditService";
import { QualificationSetAttemptService } from "./skillset/skillsetAttemptService";
import { QualificationSetRequirementService } from "./skillset/skillsetPathRequirementService";
import { SkillsetPathService } from "./skillset/skillsetPathService";
import { SkillsetService } from "./skillset/skillsetService";
import { SpaceSubscriptionService } from "./spaceSubscriptionService";
import { SpaceService } from "./spacesService";
import { SectionService } from "./stepService";
import { ActivityControlService } from "./teamService";
import { ActivityVersionService } from "./templateService";

let flowUUIDs = JSON.parse(PrivateEnv.PrivateFlowUUIDJson || PublicEnv.PublicFlowUUIDJson || "{}");

export class _REST {
   // private baseUrl: string;

   private Endpoints = {
      Auth: {
         AuthLogin: `/auth/login`,
         Users: `/users`,
         AuthRefresh: `/auth/refresh`,
         UserMe: `/users/me`,
         PasswordRequest: `/auth/password/request`,
         PasswordReset: `/auth/password/reset`,
         AuthenticatedSession: `/authentication/authenticated`,
         Session: `/api/authentication/session`
      },
      Permissions: `/permissions`,
      Notifications: `/notifications`,
      Items: {
         ActivityAttempt: `/items/activity_attempt`,
         ActivityControl: `/items/activity_control`,
         ActivityVersion: `/items/activity_version`,
         Assets: `/assets`,
         Campaign: `/items/campaign`,
         CampaignDirectusUser: `/items/campaign_directus_user`,
         CampaignEdition: `/items/campaign_edition`,
         Chat: `/items/chat`,
         ChatMessage: `/items/chat_message`,
         CommitmentConnection: `/items/commitment_connection`,
         DashboardControl: `/items/dashboard_control`,
         DashboardVersion: `/items/dashboard_version`,
         DirectusPermissions: `/permissions`,
         Document: `/items/document`,
         File: `/file`,
         Folder: `/items/folder`,
         LivekitToken: `/items/livekit_token`,
         Meeting: `/items/meeting`,
         MeetingConnection: `/items/meeting_connection`,
         Section: `/items/section`,
         SectionAsset: `/items/section_asset`,
         Asset: `/items/asset`,
         SectionDocumentEdit: `/items/section_document_edit`,
         Space: `/items/space`,
         SpaceSubscription: `/items/space_subscription`,

         Skillset: `/items/qualification_set_control`,
         SkillsetPath: `/items/qualification_set_version`,
         QualificationSetAttempt: `/items/qualification_set_attempt`,
         QualificationSetStep: `/items/qualification_set_requirement`,
         Users: `/users`
      },
      Flows: {
         Session: {
            CreateCDUSession: `/flows/trigger/${flowUUIDs.cdu_session_create}`,
            CCSessionCreate: `/flows/trigger/${flowUUIDs.cc_session_create}`
         },
         Campaign: {
            AddCdu: `/flows/trigger/${flowUUIDs.add_cdu_by_webhook}`,
            BanCDU: `/flows/trigger/${flowUUIDs.campaign_directus_user_ban}`
         }
      },

      API: {
         Hocuspocus: {
            BaseURL: PublicEnv.HocuspocusBaseURL
         },
         NextWebService: {
            CreateAttempt: `/api/activities/attempts/create`,
            CreateAttemptOptions: `/api/activities/attempts/create/options`,
            ValidateAttemptJob: `/api/activities/attempts/create/validate`,
            PermissionsPanic: `/api/dev/permissions-panic`
         }
      }
   };

   public ActivitySessionService!: ActivitySessionService;
   public CampaignSessionService!: CampaignSessionService;
   public QualificationSetAttemptService!: QualificationSetAttemptService;
   public SkillsetService!: SkillsetService;
   public QualificationSetStepService!: QualificationSetRequirementService;
   public SkillsetPathService!: SkillsetPathService;
   public ActivityAttemptService!: ActivityAttemptService;
   public ActivityControlService!: ActivityControlService;
   public ActivityVersionService!: ActivityVersionService;
   public CampaignDirectusUserService!: CampaignDirectusUserService;
   public CampaignService!: CampaignService;
   public CommitmentConnectionService!: CommitmentConnectionService;
   public DocumentService!: DocumentService;
   public CampaignEditionService!: CampaignEditionService;
   public FolderService!: FolderService;
   public SectionService!: SectionService;
   public SectionAssetService!: SectionAssetService;
   public AssetService!: AssetService;
   public SectionDocumentEditService!: SectionDocumentEditService;
   public SpaceService!: SpaceService;
   public SpaceSubscriptionService!: SpaceSubscriptionService;
   public MeetingService!: MeetingService;
   public NextService!: NextService;
   public DirectusUsersService!: DirectusUsersService;
   public DirectusNotificationsService!: DirectusNotificationsService;
   public DashboardVersionService!: DashboardVersionService;
   public DashboardControlService!: DashboardControlService;
   public MeetingConnectionService!: MeetingConnectionService;
   public ChatService!: ChatService;
   public ChatMessageService!: ChatMessageService;

   constructor(_baseUrl?: string) {
      /*       const baseUrl = _baseUrl || `https://${PublicEnv.DirectusHost}`; // || Endpoints.BaseURL; // we should use same env name for base url here

      if (!baseUrl) {
         throw new Error("BASE_URL environment variable must be set.");
      }

      this.baseUrl = baseUrl; */
      this.initialize();
   }

   private initialize() {
      this.ActivitySessionService = new ActivitySessionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Flows.Session.CCSessionCreate });
      this.CampaignSessionService = new CampaignSessionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Flows.Session.CreateCDUSession });
      this.QualificationSetAttemptService = new QualificationSetAttemptService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.QualificationSetAttempt });
      this.SkillsetService = new SkillsetService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Skillset });
      this.QualificationSetStepService = new QualificationSetRequirementService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.QualificationSetStep });
      this.SkillsetPathService = new SkillsetPathService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.SkillsetPath });
      this.ActivityAttemptService = new ActivityAttemptService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.ActivityAttempt });
      this.ActivityControlService = new ActivityControlService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.ActivityControl });
      this.ActivityVersionService = new ActivityVersionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.ActivityVersion });
      this.CampaignDirectusUserService = new CampaignDirectusUserService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.CampaignDirectusUser });
      this.CampaignService = new CampaignService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Campaign });
      this.CommitmentConnectionService = new CommitmentConnectionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.CommitmentConnection });
      this.DocumentService = new DocumentService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Document });
      this.CampaignEditionService = new CampaignEditionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.CampaignEdition });
      this.FolderService = new FolderService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Folder });
      this.SectionService = new SectionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Section });
      this.SectionAssetService = new SectionAssetService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.SectionAsset });
      this.AssetService = new AssetService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Asset });
      this.SectionDocumentEditService = new SectionDocumentEditService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.SectionDocumentEdit });
      this.SpaceService = new SpaceService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Space });
      this.SpaceSubscriptionService = new SpaceSubscriptionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.SpaceSubscription });
      this.MeetingService = new MeetingService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Meeting });
      this.MeetingConnectionService = new MeetingConnectionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.MeetingConnection });
      this.NextService = new NextService();
      this.DirectusUsersService = new DirectusUsersService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Users });
      this.DirectusNotificationsService = new DirectusNotificationsService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Notifications });
      this.DashboardVersionService = new DashboardVersionService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.DashboardVersion });
      this.DashboardControlService = new DashboardControlService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.DashboardControl });
      this.ChatService = new ChatService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.Chat });
      this.ChatMessageService = new ChatMessageService({ /* baseURL: this.baseUrl, */ endpoint: this.Endpoints.Items.ChatMessage });
   }
   public async getOne<Res>(
      args: { query?: Query<M.CustomDirectusTypes, Res[]>; token: string | undefined; endpoint: string; id?: string; throwOnNull?: boolean } & NextFetchRequestConfig
   ): Promise<Res | null> {
      if (args.id) {
         const { data: response } = await Directus.get<Res>({
            token: args.token,
            endpoint: args.endpoint,
            id: args.id,
            /* baseURL: this.baseUrl, */
            next: {
               tags: args.tags,
               revalidate: args.revalidate
            }
         });
         if (response === undefined) throw new Error(`No getOne results for id: ${args.id}`);
         if (!response) throw new Error("Invalid Directus Response.");
         return response;
      } else if (args.query) {
         const { data: response } = await Directus.search<Res[]>({
            token: args.token,
            endpoint: args.endpoint,
            query: args.query,
            /* baseURL: this.baseUrl, */
            next: {
               tags: args.tags,
               revalidate: args.revalidate
            }
         });
         if (response === undefined || (response.length === 0 && args.throwOnNull)) {
            throw new Error(`No getOne results for query: ${JSON.stringify(args.query)}`);
         } else if (response.length > 0) {
            return response[0];
         }
         return null;
      }
      throw new Error("Bad getOne args");
   }

   /*    updateBaseUrl(newBaseUrl: string) {
      this.baseUrl = newBaseUrl;
      this.initialize();
   } */
}

class REST_SINGLETON {
   private static instance: _REST;

   private constructor() {}

   public static getInstance(): _REST {
      if (!REST_SINGLETON.instance) {
         REST_SINGLETON.instance = new _REST();
      }
      return REST_SINGLETON.instance;
   }

   /*    public static updateBaseUrl(newBaseUrl: string) {
      if (!REST_SINGLETON.instance) {
         throw new Error("REST instance is not initialized yet.");
      }
      REST_SINGLETON.instance.updateBaseUrl(newBaseUrl);
   } */
}

const REST = REST_SINGLETON.getInstance();

export { REST };

// flowUUIDs = {
//    update_asset: "5d6f9f7d-7eff-4c8e-b710-46742f2d4416",
//    create_asset: "67a85ab6-d89a-415b-afdf-dec7f56e059e",
//    invite_email: "f1787696-b0f6-4cbd-bb06-4cfae056da01",
//    invite_accept: "85e59554-5d7b-4934-9f14-850d2f0b927a",
//    create_livekit_token: "ddce2a64-bf44-4b0e-826c-8ba9f6964873",
//    create_campaign_directus_user_joined: "fdc5083a-36d5-4310-8128-b0271749d0cd",
//    get_stripe_preview_invoice: "67897b1d-a432-4756-8231-3c083127cdd9",
//    create_campaign_directus_user_staff_role: "f3382ed3-d873-4147-bd89-7f3816e19eec",
//    create_activity_role_session: "3345beb2-3b42-4413-898e-3a3ded5a0716",
//    ignored: "9db7f685-f775-4af2-90c2-9c619c389c74",
//    create_stripe_subscription: "ebfc629d-1025-4a6c-8f6b-827895bc0d18",
//    update_stripe_room_quantity: "225d749d-405e-441d-94ef-a2a54ecd1e10",
//    delete_livekit_room: "46742a37-b793-48f9-92c1-96d8108bd81b",
//    create_campaign_directus_user_application: "3cf4a596-839c-4166-9802-a10a51cb39eb",
//    update_campaign_directus_user_role_id_trigger_flow: "d229187b-5c13-4f5c-80b9-7bec15c7eeae",
//    create_meeting_token: "4eeb7846-5254-4bbe-801f-a5a110cda455",
//    campaign_directus_user_ban: "1b3ce38b-e62e-49fb-80ae-a142a0b0bd4f",
//    cc_session_create: "a263236d-954a-44fc-859d-68ccb12b58de",
//    update_stripe_cancellation: "1d8c30e1-2b96-48c3-88e9-e90021165d74",
//    create_livekit_room: "7cf85a9e-5f31-47b3-a604-2581953a58fa",
//    update_stripe_payment_information: "d1759db4-51b6-4386-a53e-27997fca6c5e",
//    cdu_session_create: "b1ef3fc9-4417-40c2-830d-74a08557a53f",
//    create_access_token: "d427eb60-c27c-4223-bdee-cfe76b5bd82c",
//    create_to_do_version: "9a6af4d6-5899-42a9-b0f9-ddd967c761da",
//    create_to_do_attempt: "aa8b6a60-8a6b-4865-9e85-ff8068669fe8",
//    update_sendgrid_email: "0829cd5a-764b-4923-95e6-35cf38df9e01",
//    create_stripe_customer: "d5a877da-c55f-4245-8e45-1be0ad6d8b3b"
// };
