import { PrivateEnv } from "./PrivateEnv";
import { PublicEnv } from "./PublicEnv";

const flowUUIDs = JSON.parse(PublicEnv.PublicFlowUUIDJson || "{}");
const privateFlowUUIDs = JSON.parse(PrivateEnv.PrivateFlowUUIDJson || "{}");

export class Endpoints {
   static ApplicationBaseURL = PublicEnv.ApplicationBaseURL;
   static BaseURL = `https://${PublicEnv.DirectusHost}`;
   static WSURL = `wss://${PublicEnv.DirectusHost}/websocket`;
   static Auth = {
      AuthLogin: `/auth/login`,
      Users: `/users`,
      AuthRefresh: `/auth/refresh`,
      UserMe: `/users/me`,
      PasswordRequest: `/auth/password/request`,
      PasswordReset: `/auth/password/reset`,
      AuthenticatedSession: `/authentication/sign-in`,
      SignOut: `/authentication/sign-out`
   };

   static SchemaApply = "/schema/apply";
   static SchemaSnapshot = "/schema/snapshot";
   static SchemaDiff = "/schema/diff";
   static Operations = `/operations`;
   static Roles = `/roles`;
   static Permissions = `/permissions`;
   static Notifications = `/notifications`;

   static Items = {
      SystemUUID: `/items/system_uuid`,
      ActivityAttempt: `/items/activity_attempt`,
      ActivityControl: `/items/activity_control`,
      ActivityVersion: `/items/activity_version`,
      Asset: `/items/asset`,
      Campaign: `/items/campaign`,
      CampaignDirectusUser: `/items/campaign_directus_user`,
      CampaignEdition: `/items/campaign_edition`,
      CommitmentConnection: `/items/commitment_connection`,
      DashboardVersion: `/items/dashboard_version`,
      DirectusPermissions: `/permissions`,
      DirectusRoles: `/roles`,
      DirectusPolicies: `/policies`,
      Document: `/items/document`,
      File: `/file`,
      Folder: `/items/folder`,
      LivekitToken: `/items/livekit_token`,
      Meeting: `/items/meeting`,
      MeetingConnection: `/items/meeting_connection`,
      Section: `/items/section`,
      SectionAsset: `/items/section_asset`,
      SectionDocumentEdit: `signInIsSuccess/items/section_document_edit`,
      SectionVideo: `/items/section_video`,
      Space: `/items/space`,
      SpaceSubscription: `/items/space_subscription`,
      TipTap: `/items/tiptap`,

      QualificationSetControl: `/items/qualification_set_control`,
      QualificationSetVersion: `/items/qualification_set_version`,
      QualificationSetAttempt: `/items/qualification_set_attempt`,
      QualificationSetRequirement: `/items/qualification_set_requirement`,
      Users: `/users`
   };
   static Flows = {
      Flows: `/flows`,
      Private: {
         // Server Only!!
         AddCdu: `/flows/trigger/${privateFlowUUIDs.add_cdu_by_webhook}`,
         CampaignReadonly: `/flows/trigger/${privateFlowUUIDs.campaign_readonly_trigger_by_rest}`,
         DeleteNonModelRoles: `/flows/trigger/${privateFlowUUIDs.delete_nonmodel_roles_perms}`,
         ServersideJWT: `/flows/trigger/${privateFlowUUIDs.serverside_jwt}`
      },
      Session: {
         CreateCDUSession: `/flows/trigger/${flowUUIDs.cdu_session_create}`,
         CCSessionCreate: `/flows/trigger/${flowUUIDs.cc_session_create}`
      },
      Campaign: {
         BanCDU: `/flows/trigger/${flowUUIDs.campaign_directus_user_ban}`,
         Create: `/flows/trigger/${privateFlowUUIDs.create_campaign}`
      }
   };

   static API = {
      Hocuspocus: {
         BaseURL: PublicEnv.HocuspocusBaseURL
      },
      NextWebService: {
         CreateAttempt: `/attempts/create`,
         ConfirmCompleteAttempt: `/attempts/confirm`,
         DuplicateTemplate: `/templates/duplicate`,
         CreateAttemptOptions: `/api/activities/attempts/create/options`,
         ValidateAttemptJob: `/api/activities/attempts/create/validate`,
         PermissionsPanic: `/permissions`,
         CreateCheckout: `/account/api/checkout`
      }
   };
}
