import { apiActivitySession } from "app/(platform)/(authentication)/_api/activitySession";
import { apiCampaignSession } from "app/(platform)/(authentication)/_api/campaignSession";
import { AppDispatch } from "app/_contexts/ReduxProvider";
import { decodeJWT } from "app/_helpers/decodeJWT";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { PA } from "app/_types/PATypes";
import { Sessions } from "app/_types/Session";
import { DirectusJWTPayload } from "app/_types/Session/DirectusJWTPayload";
import getAuthenticatedSessionClientside from "./getAuthenticatedSessionClientside";

export async function getMySession({ role, tokenOverride, dispatch }: { role: PA.RoleQueries | undefined; tokenOverride: string | undefined; dispatch: AppDispatch }): Promise<{
   token: string | undefined;
   authenticatedSession?: Sessions.Authenticated.Session;
   ccSession?: Sessions.Activities.CCSession;
   cduSession?: Sessions.Campaign.CDUSession;
   roleId?: string;
}> {
   if (role?.Public || (role === undefined && !tokenOverride)) {
      if (role === undefined && !tokenOverride) {
         ILog.w("getMySession_unplanned public role use", { role });
      }
      return { token: undefined, roleId: "public" };
   }
   const authenticatedSession = getAuthenticatedSessionClientside();
   let token = tokenOverride;
   let roleId: DirectusJWTPayload["role"] | undefined = undefined;
   if (!!token) {
      roleId = decodeJWT(token as string).role;
      return { token, authenticatedSession, roleId };
   }
   if (role?.Authenticated === true) {
      if (!authenticatedSession?.access_token) {
         throw new Error(TAGS.AUTHENTICATED_ERROR);
      }
      ILog.v("getMySession_GET_AUTHENTICATED", { role });
      roleId = decodeJWT(authenticatedSession.access_token).role;
      return { token: authenticatedSession.access_token, authenticatedSession, roleId };
   }

   if (!!role?.CC) {
      ILog.v("getMySession_GET_CC_START", { role });

      if (authenticatedSession) {
         // query.filter?._and?.push({ commitment_connection_directus_users: { _eq: authenticatedSession.user.id } });
         const cc = await dispatch(
            apiActivitySession.endpoints.createActivitySession.initiate({
               query: role?.CC
            })
         ).unwrap();

         if (cc?.session?.access_token) {
            roleId = decodeJWT(cc.session.access_token).role;
            ILog.v("getMySession_GET_CC_FINISH", { role, cc, roleId });
            return { token: cc.session?.access_token, ccSession: cc, authenticatedSession, roleId };
         } else {
            throw new Error(TAGS.CC_ERROR);
         }
      }
   }
   if (!!role?.CDU) {
      if (authenticatedSession) {
         ILog.v("getMySession_GET_CDU_START", { role });
         // query.filter?._and?.push({ campaign_directus_user_user: { _eq: authenticatedSession.user.id } });
         const cdu = await dispatch(
            apiCampaignSession.endpoints.createCampaignSession.initiate({
               query: role?.CDU
            })
         ).unwrap();

         if (cdu?.session?.access_token) {
            roleId = decodeJWT(cdu.session.access_token).role;
            ILog.v("getMySession_GET_CDU_FINISH", { cdu, role, roleId });
            return { token: cdu.session.access_token, cduSession: cdu, authenticatedSession, roleId };
         } else {
            throw new Error(TAGS.CDU_ERROR);
         }
      }
   }
   ILog.e("getMySession_UNKNOWN_ERROR", { role });
   throw new Error(TAGS.UNKNOWN_ERROR);
}
