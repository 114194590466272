import { Query } from "@directus/sdk";

import { Directus } from "app/_lib/directus/DirectusRest";
import { M } from "app/_types/Schema";
import { REST } from "./REST";

export class ActivityVersionService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }
   async getOne(args: { query?: Query<M.CustomDirectusTypes, M.ActivityVersion[]>; token: string | undefined; id?: string } & NextFetchRequestConfig) {
      return REST.getOne<M.ActivityVersion>({ token: args.token, endpoint: this.endpoint, query: args.query, id: args.id, revalidate: args.revalidate, tags: args.tags });
   }
   async getMany(args: { query: Query<M.CustomDirectusTypes, M.ActivityVersion[]>; token: string | undefined } & NextFetchRequestConfig) {
      const { data: response } = await Directus.search<M.ActivityVersion[]>({
         token: args.token,
         endpoint: this.endpoint,
         query: args.query,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }

   async create(args: { token: string; payload: M.IOmit<M.ActivityVersion> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.post<M.ActivityVersion>({
         /* baseURL: this.baseUrl, */
         endpoint: this.endpoint,
         token: args.token,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }

   async updateById(args: { token: string; id: string; payload: Partial<M.ActivityVersion> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.patch<M.ActivityVersion>({
         token: args.token,
         /* baseURL: this.baseUrl, */
         endpoint: this.endpoint,
         id: args.id,
         payload: args.payload,
         next: { revalidate: args.revalidate, tags: args.tags }
      });

      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }

   async deleteById(args: { token: string; id: string } & NextFetchRequestConfig) {
      return await Directus.delete<M.ActivityVersion>({
         token: args.token,
         /* baseURL: this.baseUrl, */
         endpoint: this.endpoint,
         id: args.id,
         next: { revalidate: args.revalidate, tags: args.tags }
      });
   }
}
