import { Query } from "@directus/sdk";

import { Directus } from "app/_lib/directus/DirectusRest";
import { M } from "app/_types/Schema";
import { REST } from "./REST";

export class AssetService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }
   async getOne(args: { query?: Query<M.CustomDirectusTypes, M.Asset[]>; token: string | undefined; id?: string } & NextFetchRequestConfig) {
      return REST.getOne<M.Asset>({ token: args.token, endpoint: this.endpoint, query: args.query, id: args.id, revalidate: args.revalidate, tags: args.tags, throwOnNull: false });
   }
   async getMany(args: { query: Query<M.CustomDirectusTypes, M.Asset[]>; token: string | undefined } & NextFetchRequestConfig) {
      const { data: response } = await Directus.search<M.Asset[]>({
         token: args.token,
         endpoint: this.endpoint,
         query: args.query,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async updateById(args: { token: string; id: string; payload: Partial<M.Asset> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.patch<M.Asset>({
         token: args.token,
         endpoint: this.endpoint,
         id: args.id,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async create(args: { token: string; payload: M.IOmit<M.Asset> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.post<M.Asset>({
         token: args.token,
         endpoint: this.endpoint,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async deleteById(args: { token: string; id: string } & NextFetchRequestConfig) {
      const { data: response } = await Directus.delete<M.Asset>({
         token: args.token,
         endpoint: this.endpoint,
         id: args.id,
         /* baseURL: this.baseUrl, */
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });
      return response;
   }
}
