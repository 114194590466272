import { Query } from "@directus/sdk";
import { M } from "app/_types/Schema";
import { DateTime } from "luxon";
import { Types } from "../directus/types-helper";
import { Endpoints } from "../global/Endpoints";
import ILog from "../global/Log";
import { PublicEnv } from "../global/PublicEnv";

export declare module BaseRestParams {
   export type Get = {
      resWrapKey?: string;
      token?: string;
      baseURL: string;
      endpoint: string;
      queryParameters?: string;
      id?: string | number;
      next?: NextFetchRequestConfig;
      expectedKeys?: string[];
      additionalHeaders?: HeadersInit;
      timeout?: number; // Used to extend Gateway Timeout. Don't use unless absolutely necessary!
   };
   export interface Post<D> extends Get {
      payload?: D;
      bodyExpected?: boolean;
      expectedKeys?: string[];
      formData?: FormData;
   }

   interface Patch<D> extends Get {
      payload: D;
      expectedKeys?: string[];
   }

   interface Search<T> extends Get {
      query: Query<M.CustomDirectusTypes, T>;
      expectedKeys?: string[];
   }

   interface Delete<D> extends Get {
      id?: string;
      expectedKeys?: string[];
      payload?: D;
   }
}

/*
README:

These endpoints return a PROMISE of <T | undefined>

If the promise *resolves*, then the request has a 2xx status. If the response is undefined, then it was 
a 204 or some other 2xx status code which did not give back data (empty string, undefined, null, empty object. NOT empty arry).
If you expected data back, then handle this
as if Directus did the right thing, but did not send you data back.

If it resolves and the response is T, then we garuntee definition on all expectedKeys and the response itself.

If it REJECTS:

- The status code was non-2xx
- await resp.json() rejected
- response.data.data (Directus's Data) was undefined
- the data was not an object (also not undefined, null, "")

You *must* handle the reject case, and the undefined data case (unless undefined data is intended).

Example:
                                                           |  Handing reject case  |
   const {data: response} = await Directus.post<Type>({ . . . }).catch((e) => { . . . })
   if (response) return response;
   | handling undefined case |
   else throw "Request returned unexpected data.";

See types-helper definedunknownRespData for more details.

*/

// For all methods: T: Response Data, D?: Request Payload
// D is only used for methods with a request payload.

export const CORS_MODE = PublicEnv.NodeEnv === "development" ? "cors" : "no-cors";

export class BaseRest {
   static migrationActive({ timestamp }: { timestamp?: number }): boolean {
      let migrationTimestamp = timestamp; //|| Number(PublicEnv.MigrationTimestamp);
      ILog.v("migrationActive", { migrationTimestamp, timestamp, env: PublicEnv.MigrationTimestamp });
      if (!migrationTimestamp) return false;
      else {
         const diff = DateTime.fromSeconds(migrationTimestamp).diffNow("seconds").seconds;
         const res = diff < 0 || diff === 0;

         ILog.v("migrationActive", { diff, res });
         return res;
      }
   }
   static Setup({ token, timeout, additionalHeaders }: { token?: string; timeout?: number; additionalHeaders?: HeadersInit }): Promise<{
      headers: HeadersInit;
      controller?: AbortController;
      timeoutId?: NodeJS.Timeout;
   }> {
      if (this.migrationActive({})) return Promise.reject();
      else {
         let headers = additionalHeaders ?? {};

         token &&
            Object.assign(headers, {
               Authorization: `Bearer ${token}`
            });

         return Promise.resolve({
            headers,
            controller: timeout ? new AbortController() : undefined,
            timeoutId: timeout ? setTimeout(() => timeout && timeout > 0 && timeout < 10000 && timeout, timeout) : undefined
         });
      }
   }

   static async handleResponse<T>({
      timeoutId,
      res,
      resWrapKey,
      expectedKeys
   }: {
      timeoutId: NodeJS.Timeout | undefined;
      res: Response;
      resWrapKey?: string;
      expectedKeys?: string[];
   }): Promise<T | undefined> {
      return new Promise(async (resolve, reject) => {
         timeoutId && clearTimeout(timeoutId);
         if (res.ok) {
            const data = await Types.definedunknownRespData<T>(res, expectedKeys);
            if (data.resolve) {
               resolve(data.value);
            } else {
               reject({ res, data });
            }
         } else {
            return reject({ res });
         }
      });
   }

   static async get<T>({ token, additionalHeaders, baseURL, endpoint, queryParameters, id, next, expectedKeys, timeout, resWrapKey }: BaseRestParams.Get): Promise<T | undefined> {
      const { headers, controller, timeoutId } = await this.Setup({
         token,
         additionalHeaders,
         timeout
      });

      ILog.v("GET", { baseURL, endpoint, queryParameters, id, timeout });

      return await fetch(`${baseURL}${endpoint}${id ? `/${id}` : ""}${queryParameters ? queryParameters : ""}`, {
         // mode: CORS_MODE,
         headers: headers,
         method: "GET",
         signal: controller?.signal,
         next
      }).then(async (res: Response) =>
         this.handleResponse({
            timeoutId,
            res,
            resWrapKey,
            expectedKeys
         })
      );
   }

   static async post<T, D>({ token, baseURL, endpoint, resWrapKey, payload, next, expectedKeys, timeout, formData, additionalHeaders }: BaseRestParams.Post<D>): Promise<T | undefined> {
      const { headers, controller, timeoutId } = await this.Setup({
         token,
         timeout,
         additionalHeaders
      });

      return await fetch(`${baseURL}${endpoint}`, {
         // mode: CORS_MODE,
         headers,
         method: "POST",
         body: payload ? JSON.stringify(payload) : formData ? formData : undefined,
         signal: controller?.signal,
         next
      }).then(async (res: Response) => {
         ILog.v("POST resolved", { baseURL, endpoint, timeout });

         return this.handleResponse({
            timeoutId,
            res,
            resWrapKey,
            expectedKeys
         });
      });
   }
   static async patch<T, D>({ endpoint, id, additionalHeaders, payload, token, expectedKeys, resWrapKey, baseURL, timeout, next }: BaseRestParams.Patch<D>): Promise<T | undefined> {
      const { headers, controller, timeoutId } = await this.Setup({
         token,
         additionalHeaders,
         timeout
      });

      ILog.v("PATCH", { baseURL, endpoint, id, payload, timeout });

      return await fetch(`${baseURL}${endpoint}${id ? `/${id}` : ""}`, {
         // mode: CORS_MODE,
         headers: headers,
         method: "PATCH",
         body: JSON.stringify(payload),
         signal: controller?.signal,
         next
      }).then(async (res: Response) =>
         this.handleResponse({
            timeoutId,
            res,
            resWrapKey,
            expectedKeys
         })
      );
   }

   static async search<T, D>({ token, additionalHeaders, endpoint, query, baseURL, resWrapKey, expectedKeys, timeout, next }: BaseRestParams.Search<D>): Promise<T | undefined> {
      const { headers, controller, timeoutId } = await this.Setup({
         token,
         additionalHeaders,
         timeout
      });

      ILog.v("SEARCH", { baseURL, endpoint, query, timeout });

      return await fetch(`${baseURL}${endpoint}`, {
         // mode: CORS_MODE,
         headers: headers,
         method: "SEARCH",
         body: JSON.stringify({ query: query }),
         // signal: controller?.signal,
         next
      }).then(async (res: Response) =>
         this.handleResponse({
            timeoutId,
            res,
            resWrapKey,
            expectedKeys
         })
      );
   }

   static async delete<T, D>({ token, additionalHeaders, endpoint, id, expectedKeys, resWrapKey, payload, baseURL, timeout, next }: BaseRestParams.Delete<D>): Promise<T | undefined> {
      const { headers, controller, timeoutId } = await this.Setup({
         token,
         additionalHeaders,
         timeout
      });

      ILog.v("DELETE", { baseURL, endpoint, id, payload, timeout });

      return await fetch(`${baseURL ? baseURL : Endpoints.BaseURL}${endpoint}${id ? `/${id}` : ""}`, {
         // mode: CORS_MODE,
         headers: headers,
         method: "DELETE",
         body: id ? JSON.stringify({ id: id }) : payload ? JSON.stringify(payload) : undefined,
         signal: controller?.signal,
         next
      }).then(async (res: Response) =>
         this.handleResponse({
            timeoutId,
            res,
            resWrapKey,
            expectedKeys
         })
      );
   }
}
