import { Query } from "@directus/sdk";
import { Directus } from "app/_lib/directus/DirectusRest";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { REST } from "./REST";

export class CampaignService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }
   async getOne(args: { query?: Query<M.CustomDirectusTypes, M.Campaign[]>; token: string | undefined; id?: string } & NextFetchRequestConfig) {
      return REST.getOne<M.Campaign>({ token: args.token, endpoint: this.endpoint, query: args.query, id: args.id, revalidate: args.revalidate, tags: args.tags });
   }
   async getMany(args: { query: Query<M.CustomDirectusTypes, M.Campaign[]>; token: string | undefined } & NextFetchRequestConfig) {
      const { data: response } = await Directus.search<M.Campaign[]>({
         token: args.token,
         endpoint: this.endpoint,
         query: args.query,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      ILog.v("Campaing getMany", { query: args.query, response });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }

   async updateById(args: { token: string; id: string; payload: Partial<M.Campaign> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.patch<M.Campaign>({
         token: args.token,
         endpoint: this.endpoint,
         id: args.id,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });

      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
}
