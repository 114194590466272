import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { v4 } from "uuid";
import { DirectusWSClient } from "./WSNamespaced";

export type DirectusWSArgOptions<T> = {
   type: "items";
   action: "read" | "create" | "update" | "delete";
   collection: keyof M.CustomDirectusTypes;
   id?: string;
   ids?: string[];
   data: Partial<T> | Partial<T>[];
};

type DirectusWSMessage<T> = Record<string, any>;

export type SocketEmitFunction<T = undefined> = () => Promise<SocketEmitAction<T>>;
export type SocketEmitAction<T = undefined> = {
   tags: {
      type: string;
      id: string;
   }[];
   status: number;
   data?: T;
   error?: string;
};
export const socketEmitAsPromise = <T>(client: DirectusWSClient) => {
   return <TData extends Record<string, any>>(data: TData): Promise<{ data: T } | { error: any }> => {
      return new Promise((resolve, reject) => {
         const requestId = v4();
         const payload = { ...data, uid: requestId };
         ILog.v("socketEmitAsPromise_payload1", { payload });
         const remove = client.onWebSocket("message", (message: any) => {
            ILog.v("socketEmitAsPromise_2", { message });
            if (message.uid === requestId) {
               ILog.v("socketEmitAsPromise_3", { message });
               if (message.status === "error") {
                  ILog.v("socketEmitAsPromise_error4", { error: message });
                  reject({ error: message });
               } else {
                  ILog.v("socketEmitAsPromise_resolve5", { data: message?.data || message });
                  resolve({ data: message?.data });
               }
               ILog.v("socketEmitAsPromise_remove6", { remove });
               remove();
            }
         });

         client.sendMessage(payload);
      });
   };
};

export async function awaitSocketResponseWithoutCleanup<TData extends Record<string, any>>(socket: DirectusWSClient, data: TData): Promise<unknown> {
   return new Promise((resolve, reject) => {
      const uid = v4();
      const payload = { ...data, uid };
      ILog.v("awaitSocketResponse_payload1", { payload });
      const remove = socket.onWebSocket("message", (message: any) => {
         ILog.v("awaitSocketResponse_2", { message });
         if (message.uid === uid) {
            remove();
            ILog.v("awaitSocketResponse_3", { message });
            if (message.status === "error") {
               ILog.e("awaitSocketResponse_error4", { error: message });
               reject(message);
            } else {
               ILog.v("awaitSocketResponse_resolve5", { data: message?.data || message });
               resolve(message?.data || message);
            }
            ILog.v("awaitSocketResponse_remove6", { remove });
         }
      });

      socket.sendMessage(payload);
   });
}
export async function socketResponse<R, TData extends Record<string, any>>(socket: DirectusWSClient, payloadMessage: TData): Promise<R> {
   return new Promise((resolve, reject) => {
      const uid = v4();
      const payload = { ...payloadMessage, uid };
      const bool = true; // payloadMessage?.collection === "activity_attempt";
      ILog.v("awaitSocketMessageResponse_payload1", { payload });
      const remove = socket.onWebSocket("message", (message: any) => {
         bool && ILog.v("awaitSocketMessageResponse_2", { message });
         if (message.uid === uid) {
            bool && ILog.v("awaitSocketMessageResponse_3", { message });
            if (message.status === "error") {
               bool && ILog.e("awaitSocketMessageResponse_error4", { error: message });
               reject(message);
            } else {
               bool && ILog.v("awaitSocketMessageResponse_resolve5", { data: message?.data, message });
               resolve(message?.data);
            }
            bool && ILog.v("awaitSocketMessageResponse_remove6", { remove });
            remove();
         } else if (message.status === "error") {
            bool && ILog.e("awaitSocketMessageResponse_error4", { error: message });
            reject(message);
         }
      });

      socket.sendMessage(payload);
   });
}
