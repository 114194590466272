import { DirectusClient, WebSocketClient, createDirectus, realtime } from "@directus/sdk";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { AppDispatch } from "app/_contexts/ReduxProvider";
import { Endpoints } from "app/_lib/global/Endpoints";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { awaitSocketResponseWithoutCleanup } from "./socketEmitAsPromise";
// export type DirectusWSClient = DirectusClient<M.CustomDirectusTypes> & StaticTokenClient<M.CustomDirectusTypes> & WebSocketClient<M.CustomDirectusTypes>;
export type DirectusWSClient = DirectusClient<M.CustomDirectusTypes> & WebSocketClient<M.CustomDirectusTypes>;

// Store role object in the class, and refetch inside on a timeout.
// Invalidating TAGS.RoleObject in RTK
interface RoleObject extends PA.RoleQueries {
   roleId: string;
}
export class _DIRECTUS_WS {
   public client: DirectusWSClient | undefined;
   public _token: string | undefined;
   public namespace: string;
   public roleQuery: PA.RoleQueries;
   public connected: boolean = false;
   public requestIds: string[] = [];
   public isConnecting: boolean = false;
   public isCreatingClient: boolean = false;
   public dispatch: AppDispatch;

   constructor({ roleQuery, dispatch, tokenOverride }: { roleQuery: RoleObject; dispatch: AppDispatch; tokenOverride?: string }) {
      ILog.v("_DIRECTUS_WS_CONSTRUCT", {});
      this._token = tokenOverride;
      this.roleQuery = roleQuery;
      this.namespace = roleQuery.roleId;
      this.client = undefined;
      this.dispatch = dispatch;
      this.isConnecting = false;
      this.isCreatingClient = false;
   }

   public async initialize(tokenOverride?: string) {
      ILog.v("_DIRECTUS_WS_initialize", { client: this.client, roleId: this.roleQuery.roleId });

      await this.refreshToken({ tokenOverride });
   }

   async updateToken(token: string, awaitConnected: boolean = true) {
      ILog.v("updateToken", { token });
      if (this._token === token) {
         ILog.v("_DIRECTUS_WS_UPDATE_TOKEN_SAME", { token });
         return;
      }
      ILog.v("_DIRECTUS_WS_UPDATE_TOKEN", {});
      if (!this.client) {
         ILog.e("_DIRECTUS_WS_UPDATE_TOKEN_NO_CLIENT", { client: this.client });
         throw new Error("No client found");
      }
      if (this.connected === false && awaitConnected === true) {
         ILog.e("_DIRECTUS_WS_UPDATE_TOKEN_NOT_CONNECTED", { connected: this.connected });
         throw new Error("Not connected");
      }
      const res = await awaitSocketResponseWithoutCleanup(this.client!, { type: "auth", access_token: token });
      //@ts-expect-error
      if (res?.status !== "ok") {
         ILog.v("_DIRECTUS_WS_UPDATE_TOKEN_FAILED", { res });
         throw new Error("Failed_to_update_token");
         // return null;
      } else {
         this._token = token;
         // We have to set token in the client's state, otherwise it will attempt refreshing with the old token. setToken does not actually send a request upon setting.
         // this.client!.setToken(token);
      }
      // return this.client;
   }
   async refreshToken({ dispatchTimeout, tokenOverride }: { dispatchTimeout?: boolean; tokenOverride?: string }) {
      ILog.v("_DIRECTUS_WS_REFRESH_TOKEN", {});
      if (this.requestIds.length === 0) {
         ILog.v("_DIRECTUS_WS_REFRESH_TOKEN_NO_REQUESTS", { requestIds: this.requestIds });
         return;
      }
      const session = await getMySession({ role: this.roleQuery, tokenOverride, dispatch: this.dispatch });

      if (session.token) {
         if (!this.client) {
            ILog.v("_DIRECTUS_WS_REFRESH_TOKEN_NO_CLIENT", {});
            this.client = createDirectus<M.CustomDirectusTypes>(`${Endpoints.WSURL}?${this.namespace}`).with(
               realtime({
                  reconnect: false
               })
            );
            await this.client.connect();
            await this.updateToken(session.token, false);
            this.connected = true;
            this.isConnecting = false;
            this.isCreatingClient = false;
         } else if (!!this.client && !this.connected) {
            await this.client.connect();
            await this.updateToken(session.token, false);
            this.connected = true;
            this.isConnecting = false;
            this.isCreatingClient = false;
         }

         ILog.v("_DIRECTUS_WS_REFRESH_TOKEN_TOKEN_UPDATED_dispatching_timeout", { session });
         const parsePayload = () => {
            if (session?.authenticatedSession?.expires) {
               return { expires: session.authenticatedSession.expires, roleId: PublicEnv.DirectusAuthenticatedRoleId! };
            }
            if (session?.cduSession?.session?.expires) {
               return { expires: session.cduSession?.session?.expires, roleId: session.ccSession?.commitment_connection_directus_roles[0] };
            }
            if (session?.ccSession?.session?.expires) {
               return { expires: session?.ccSession?.session?.expires, roleId: session.cduSession?.campaign_directus_user_directus_roles as string };
            }

            throw new Error("No session in payload");
         };
         const { expires, roleId } = parsePayload();
         const finalExpires = DateTime.fromMillis(expires).diffNow().as("milliseconds") * 0.9;
         const timeout = setTimeout(async () => {
            ILog.v("dispatchTimeout_refreshToken", {});
            clearTimeout(timeout);
            await this.refreshToken({});
         }, finalExpires);

         // setTimeout(() => {
         //    this.client?.disconnect();
         // }, 20000);
         await this.updateToken(session.token);
      } else {
         throw new Error("refreshToken_No_token_found");
      }
   }
   async waitForConnected() {
      return new Promise(async (resolve, reject) => {
         const promiseId = v4();
         let i = 0;
         while (this.connected === false) {
            i++;
            if (this.isConnecting === false) {
               this.isConnecting = true;
               await this.initialize();
            } else {
               await new Promise((resolve) => setTimeout(resolve, 200));
            }
            if (i > 200) {
               ILog.e("waitForConnected_While", { this: this, isConnected: this.connected, promiseId });
               reject("Timeout");
               throw new Error("waitForConnected_Timeout");
            }
            ILog.v("waitForConnected_WHILE", { this: this, isConnected: this.connected, promiseId });
         }
         ILog.v("waitForConnected_RESOLVED", { this: this, isConnected: this.connected, promiseId });
         resolve(true);
      });
   }
   async waitForClient() {
      return new Promise(async (resolve, reject) => {
         const promiseId = v4();
         let i = 0;
         while (this.client === undefined) {
            i++;
            if (this.isCreatingClient === false) {
               this.isCreatingClient = true;
               this.isConnecting = true;
               await this.initialize();
            } else {
               await new Promise((resolve) => setTimeout(resolve, 200));
            }
            if (i > 200) {
               ILog.e("waitForClient_While", { this: this, isConnected: this.connected, promiseId });
               reject("Timeout");
               throw new Error("waitForClient_Timeout");
            }
            ILog.v("waitForClient_WHILE", { this: this, isConnected: this.connected, promiseId });
         }
         ILog.v("waitForClient_RESOLVED", { this: this, isConnected: this.connected, promiseId });
         resolve(true);
      });
   }
   public addRequestId(requestId: string) {
      const alreadyAdded = this.requestIds.includes(requestId);

      if (!alreadyAdded) {
         ILog.v("addRequestId", { requestId });
         this.requestIds.push(requestId);
      }
   }
   public removeRequestId(requestId: string) {
      this.requestIds = this.requestIds.filter((id) => id !== requestId);
      ILog.v("removeRequestId", { requestId, remaining: this.requestIds });
      if (this.requestIds.length === 0) {
         ILog.v("removeRequestId_no_requests", { requestId, remaining: this.requestIds });
         DIRECTUS_WS_NAMESPACED.waitToRemoveInstance(this.namespace);
         // this.connected = false;
         // this.client?.disconnect();
      }
   }
}

class DIRECTUS_WS_NAMESPACED {
   private static instances: Map<string, _DIRECTUS_WS>;

   public static async getInstance({
      roleQuery,
      requestId,
      dispatch,
      tokenOverride
   }: {
      roleQuery: PA.RoleQueries;
      requestId: string;
      dispatch: AppDispatch;
      tokenOverride?: string;
   }): Promise<_DIRECTUS_WS | null> {
      const instances = DIRECTUS_WS_NAMESPACED.instances;
      let finalRoleQuery = roleQuery as RoleObject;
      if (!finalRoleQuery.roleId) {
         const { roleId } = await getMySession({ role: roleQuery, tokenOverride: undefined, dispatch });
         if (!roleId) throw new Error("No role id found");
         finalRoleQuery = { ...roleQuery, roleId } as RoleObject;
      } else {
         ILog.v("DIRECTUS_WS_HAS_ROLE_ID", { roleQuery });
      }
      if (!instances) {
         DIRECTUS_WS_NAMESPACED.instances = new Map<string, _DIRECTUS_WS>();
      }
      const instance = DIRECTUS_WS_NAMESPACED.instances?.get(finalRoleQuery.roleId);
      if (!instance) {
         ILog.v("DIRECTUS_WS_INIT", { instance: DIRECTUS_WS_NAMESPACED.instances });
         const newInstance = new _DIRECTUS_WS({ roleQuery: finalRoleQuery, dispatch });

         DIRECTUS_WS_NAMESPACED.instances.set(finalRoleQuery.roleId, newInstance);
         newInstance.addRequestId(requestId);
         newInstance.isConnecting = true;
         newInstance.isCreatingClient = true;
         await newInstance.initialize(tokenOverride);
      }

      const setInstance = DIRECTUS_WS_NAMESPACED.instances.get(finalRoleQuery.roleId);
      if (!setInstance) {
         throw new Error("Instance_not_set");
      }
      setInstance.addRequestId(requestId);
      if (!setInstance.client) {
         await setInstance.waitForClient();
      }
      if (!setInstance.connected) {
         await setInstance.waitForConnected();
      }

      ILog.v("DIRECTUS_WS_SINGLETON_EXISTS", { instance: DIRECTUS_WS_NAMESPACED.instances });

      return setInstance;
   }

   public static async waitToRemoveInstance(roleId: string) {
      let requestIdCount = 0;
      let i = 0;

      while (requestIdCount === 0) {
         const instance = DIRECTUS_WS_NAMESPACED.instances.get(roleId);

         if (!instance) {
            ILog.v("No_instance_found", { roleId, instance });
            break;
         }

         requestIdCount = instance.requestIds.length;
         i++;
         await new Promise((resolve) => setTimeout(resolve, 100 * i));
         if (requestIdCount > 0) {
            break;
         } else if (i > 10) {
            DIRECTUS_WS_NAMESPACED.removeInstance(roleId);
         }
      }
   }
   public static removeInstance(roleId: string) {
      const instance = DIRECTUS_WS_NAMESPACED.instances.get(roleId);
      if (!instance) {
         ILog.v("No_instance_found", { roleId, instance });
         return;
      }
      ILog.v("Removing_instance", { roleId, instance });
      DIRECTUS_WS_NAMESPACED.instances.delete(roleId);
      if (instance.connected) {
         ILog.v("Disconnecting_instance", { roleId, instance });
         instance.connected = false;
         if (instance.client) {
            instance.client.disconnect();
         }
         // instance.client = undefined;
         ILog.v("Instance_disconnected", { roleId, instance, client: instance.client });
      } else {
         ILog.v("Instance_already_disconnected", { roleId, instance });
      }
   }
}

const WS_NAMESPACED = ({ roleQuery, requestId, dispatch, tokenOverride }: { roleQuery: PA.RoleQueries; requestId: string; dispatch: AppDispatch; tokenOverride?: string }) =>
   DIRECTUS_WS_NAMESPACED.getInstance({ roleQuery, requestId, dispatch, tokenOverride });

export { WS_NAMESPACED };
