import { Query } from "@directus/sdk";

import meetingPassword from "app/(platform)/meeting-room/_actions/meetingPassword";
import { Directus } from "app/_lib/directus/DirectusRest";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { REST } from "./REST";

export class MeetingService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }
   async getOne(args: { query?: Query<M.CustomDirectusTypes, M.Meeting[]>; token: string | undefined; id?: string } & NextFetchRequestConfig) {
      return REST.getOne<M.Meeting>({ token: args.token, endpoint: this.endpoint, query: args.query, id: args.id, revalidate: args.revalidate, tags: args.tags });
   }
   public async getMany(args: { query: Query<M.CustomDirectusTypes, M.Meeting[]>; token: string | undefined } & NextFetchRequestConfig) {
      ILog.v("getManyMeeting sending search");

      const { data: response } = await Directus.search<M.Meeting[]>({
         token: args.token,
         endpoint: this.endpoint,
         query: args.query,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      ILog.v("getManyMeeting meetingService", { response });

      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   public async generateMeetingPassword(args: { userIdOverride?: string; tokenOverride?: string; meetingId: string } & NextFetchRequestConfig) {
      const res = await meetingPassword({ meetingID: args.meetingId, regenerate: true, tokenOverride: args.tokenOverride, userIdOverride: args.userIdOverride });

      return res.data;
   }

   public async create(args: { token?: string; payload: M.IOmit<M.Meeting> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.post<M.Meeting>({
         token: args.token,
         endpoint: this.endpoint,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });

      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }

   public async updateById(args: { token: string; id: string; payload: Partial<M.Meeting> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.patch<M.Meeting>({
         token: args.token,
         endpoint: this.endpoint,
         id: args.id,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });

      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
}
