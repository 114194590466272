import { Query, SubscriptionEvents } from "@directus/sdk";
import { AppDispatch } from "app/_contexts/ReduxProvider";
import { handleRTKError } from "app/_services/redux/api/_helpers/rtkHelpers";
import { WS_NAMESPACED } from "app/_services/ws/WSNamespaced";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { v4 } from "uuid";
import ILog from "../global/Log";

// Public role on WS isn't allowed by Directus
export type DirectusWSEvent<T> = {
   type: "subscription" | "auth";
   data: T;
   event: SubscriptionEvents;
   uid: string;
   status: string;
};

export type TryDirectusWSParams<T> = {
   uidArgs?: string;
   collection: string;
   query: Query<M.CustomDirectusTypes, T>;
   // client: DirectusClient<M.CustomDirectusTypes> & StaticTokenClient<M.CustomDirectusTypes> & WebSocketClient<M.CustomDirectusTypes>;
   onUpdateCachedData: (message: DirectusWSEvent<T>) => void;

   cacheEntryRemoved: Promise<void>;
   cacheDataLoaded: any;

   getCacheEntry: any;
   role: PA.RoleQueries | undefined;
   dispatch: AppDispatch;
};

export async function tryDirectusWS<T>({ role, getCacheEntry, cacheDataLoaded, collection, query, uidArgs, onUpdateCachedData, cacheEntryRemoved, dispatch }: TryDirectusWSParams<T[]>) {
   await cacheDataLoaded;
   const queryUID = uidArgs + v4() + `_collection_${collection}`;
   // const { token, roleId } = await getMySession({ role, tokenOverride: undefined });
   if (!role) throw new Error("no role found");
   const requestId = getCacheEntry()?.requestId;
   const ws = await WS_NAMESPACED({ roleQuery: role, requestId, dispatch });
   const client = ws?.client;
   if (!client) throw new Error("No client found");
   const messageListener = client.onWebSocket("message", function (message: DirectusWSEvent<T[]>) {
      const { type, data, uid, event, status } = message;
      ILog.v("updateCachedData", { data, type, uid, event });

      if (type === "auth") {
         if (status !== "ok") {
            ILog.v("WS_AUTH_ERROR", { type, data, uid, event, status });

            throw new Error(`WS_AUTH_ERROR  ${type} ${data} ${uid} ${event} ${status}`);
         }
         ILog.v("onmessage", { type, data, uid, event, status });
         return;
      }
      if (uid !== queryUID) return;

      onUpdateCachedData(message);
   });

   function closeEvent(e: CloseEvent, ...args: any[]) {
      ILog.w("onclose", { requestId, e, args });
      ws?.removeRequestId(requestId);
      // return;
      // return handleRTKError({ endpoint: "tryDirectusWS", error: "CLOSE", query });
   }

   const closeListener = client.onWebSocket("close", closeEvent);

   const errorListener = client.onWebSocket("error", function (error) {
      ILog.e("onerror", { error, requestId });
      ws.removeRequestId(requestId);
      return handleRTKError({ endpoint: "tryDirectusWS", error, query });
   });

   const removeListeners = (subscription: any) => {
      ILog.v("removeListeners", { requestId, subscription });
      messageListener();
      closeListener();
      errorListener();
      subscription.unsubscribe();
   };
   try {
      // ILog.v("tryDirectusWS", { client, collections: collection, query, uid: uidArgs });
      //@ts-expect-error
      const subscription = await client.subscribe(collection as keyof M.CustomDirectusTypes, { uid: queryUID, query });

      ILog.v("subscribed", { collection, uid: uidArgs + `_collection_${collection}`, query });

      await cacheEntryRemoved;
      removeListeners(subscription);

      if (ws && requestId) {
         ILog.v("removeRequestId", { requestId });
         ws.removeRequestId(requestId);
      }
   } catch (e) {
      if (ws && requestId) {
         ws.removeRequestId(requestId);
      }
      return handleRTKError({ endpoint: "tryDirectusWS", error: e });
   }
}
