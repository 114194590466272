import ILog from "app/_lib/global/Log";
import { Sessions } from "app/_types/Session";

export default function getAuthenticatedSessionClientside(): Sessions.Authenticated.Session | undefined {
   const cookie = document.cookie
      .split("; ")
      .filter((row) => row.startsWith(`auth_session=`))
      .map((c) => c.split("=")[1])[0];
   if (cookie === undefined) {
      return undefined;
   }
   ILog.v("getAuthenticatedSessionClientside decodeURIComponent", { cookie });
   const decodedCookie = decodeURIComponent(cookie);
   if (!decodedCookie) return undefined;
   const value = JSON.parse(decodedCookie) as Sessions.Authenticated.CookieValue;
   if (value?.authenticated) {
      return value.authenticated;
   } else {
      return undefined;
   }
}
