import { Query } from "@directus/sdk";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { M } from "app/_types/Schema";
import { Sessions } from "app/_types/Session";
import { TAGS } from "../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";

export const apiActivitySession = apiService.injectEndpoints({
   endpoints: (builder) => ({
      createActivitySession: builder.query<Sessions.Activities.CCSession, { query: Query<M.CustomDirectusTypes, M.CommitmentConnection[]> }>({
         queryFn: async (arg, api) => {
            try {
               const authSession = getAuthenticatedSessionClientside();

               if (!authSession?.access_token) throw new Error("authSession is undefined");

               ILog.v("apiActivitySession_create", { arg, authSession });

               if (!arg.query) throw new Error(`apiActivitySession_create no token or roleId found ${arg.query} `);
               // const requestId = v4();
               // const ws = await WS_NAMESPACED({ roleQuery: calculateAuthenticatedRole(), requestId, dispatch: api.dispatch, tokenOverride: authSession.access_token });
               // const client = ws?.client;
               // if (!client) throw new Error("No client found");

               // ILog.v("emitWSMessage", { ws, client, requestId });
               // const res = await socketResponse<M.CommitmentConnection[] | null, Record<string, any>>(client, {
               //    type: "items",
               //    action: "read",
               //    collection: "commitment_connection",
               //    query: arg.query
               // });
               // ILog.v("emitWSMessage", { res });
               // ws.removeRequestId(requestId);
               // let cc = null;
               // if (!res) {
               //    cc = await REST.CommitmentConnectionService.getOne({
               //       token: authSession.access_token,
               //       query: arg.query
               //    });
               // } else {
               //    cc = res[0];
               // }

               const cc = await REST.CommitmentConnectionService.getOne({
                  token: authSession.access_token,
                  query: arg.query
               });
               if (!cc) throw new Error(TAGS.CC_ERROR);

               const session = await REST.ActivitySessionService.createSession({
                  token: authSession.access_token,
                  payload: { commitment_connection: cc.id, role_type: cc.commitment_connection_type }
               });

               if (!session) throw new Error(TAGS.CC_ERROR);

               const CCSession = { ...cc, session: session };

               ILog.v("apiActivitySession_create", { CCSession });

               return { data: CCSession };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiActivitySession_createActivitySession", query: arg });
            }
         },
         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.ACTIVITY_SESSION, includeList: true }]
            })
      })
      // askDirectusToSetCCRoles: builder.mutation<Sessions.Activities.CCSession, { cc: M.CommitmentConnection }>({
      //    queryFn: async (arg, api) => {
      //       try {
      //          const authSession = getAuthenticatedSessionClientside();

      //          if (!authSession?.access_token) throw new Error("authSession is undefined");

      //          const cc = arg.cc;

      //          if (!cc) throw new Error(TAGS.CC_ERROR);

      //          const session = await REST.ActivitySessionService.createSession({
      //             token: authSession.access_token,
      //             payload: { commitment_connection: cc.id, role_type: cc.commitment_connection_type }
      //          });

      //          if (!session) throw new Error(TAGS.CC_ERROR);

      //          const CCSession = { ...cc, session: session };

      //          ILog.v("apiActivitySession_create", { CCSession });

      //          return { data: CCSession };
      //       } catch (error: any) {
      //          return handleRTKError({ error, endpoint: "apiActivitySession_createActivitySession" });
      //       }
      //    },
      //    invalidatesTags: (result, error, query) =>
      //       handleRTKTags<typeof result, typeof error, typeof query>({
      //          result,
      //          error,
      //          query,
      //          tagSelectors: []
      //       })
      // })
   }),
   overrideExisting: true
});
