import { VariantProps, cva } from "class-variance-authority";
import React from "react";

interface HeaderProps extends VariantProps<typeof headerClasses> {
   label: string | null | undefined;
   labelFallback?: string;
   level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
   className?: string;
   testId?: string;
   id?: string;
}

const headerClasses = cva("font-semibold", {
   variants: {
      color: {
         white: "text-white",
         gray: "text-gray-500",
         primary: "text-slate-900",
         light: "text-slate-50",
         red: "text-red-300",
         green: "text-green-500",
         blue: "text-blue-500"
      },
      variant: {
         solid: "",
         outline: "border",
         sectionHeading: "border-b mb-2 mt-2"
      },
      margin: {
         none: "",
         4: "m-4"
      },
      padding: {
         none: "",
         sm: "px-2.5 py-0.5"
      },
      wrap: {
         wrap: "whitespace-normal",
         nowrap: "whitespace-nowrap"
      },
      level: {
         h1: "text-4xl font-bold",
         h2: "text-3xl font-semibold",
         h3: "text-2xl font-semibold",
         h4: "text-xl font-semibold",
         h5: "text-lg font-semibold",
         h6: "text-base font-medium"
      }
   },
   defaultVariants: {
      color: "primary",
      variant: "solid"
   }
});

const Header: React.FC<HeaderProps> = ({
   label,
   level = "h1",
   color,
   variant,
   className = "",
   testId = null,
   id = testId,
   margin = "none",
   padding = "none",
   wrap = "wrap",
   labelFallback = "Draft"
}) => {
   const combinedClasses = headerClasses({ color, variant, margin, padding, wrap, level }) + ` ${className}`;

   return React.createElement(
      level,
      {
         id: id,
         "data-testid": testId,
         className: combinedClasses
      },
      !!label ? label : labelFallback
   );
};

export default Header;
