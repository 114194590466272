import { Query } from "@directus/sdk";
import { M } from "app/_types/Schema";
import { Endpoints } from "../global/Endpoints";
import ILog from "../global/Log";
import { BaseRest } from "../rest/BaseRest";
import { WarnServerMisuedDirectusRest } from "./MisusedDirectusRest";
import { Types } from "./types-helper";

type PayloadOptions = any;

// These would be better if they extended BaseRestParams
type DirectusGetParams = {
   token?: string;
   baseURL?: string; // This should ONLY be passed in rare cases, like Admin, where a different DIRECTUS instance is used.
   endpoint: string;
   headers?: HeadersInit;
   queryParameters?: string;
   id?: string | number;
   next?: NextFetchRequestConfig;
   expectedKeys?: string[];
   timeout?: number; // Used to extend Gateway Timeout. Don't use unless absolutely necessary!
   objectOnly?: boolean; // Will return only directus.data, not the entire response (Such as meta & aggregate)
};
interface DirectusPostParams<T = any> extends DirectusGetParams {
   payload?: T;
   bodyExpected?: boolean;
   expectedKeys?: string[];
   formData?: FormData;
}

interface DirectusPatchParams<T> extends DirectusGetParams {
   payload: Partial<T>;
   expectedKeys?: string[];
}

interface DirectusSearchParams<T> extends DirectusGetParams {
   query: Query<M.CustomDirectusTypes, T>;
   expectedKeys?: string[];
}

interface DirectusDeleteParams extends DirectusGetParams {
   id?: string;
   expectedKeys?: string[];
   payload?: PayloadOptions;
}
export interface DirectusCatchError {
   statusText: Response["statusText"]; //AxiosResponse<any, any>["statusText"];
}

export type BaseRestResponse<T> =
   | {
        // Aggregate will be in data if passed in the query. This is just how Directus handles it.
        data: T;
        meta?: {
           total_count?: number;
           filter_count?: number;
        };
     }
   | undefined;

export interface DirectusResponse<T> {
   data?: T;
   meta?: {
      total_count?: number;
      filter_count?: number;
   };
}

// If you get a throw & the status is 200 | 204, it is likely that your expectedKeys are missing, or the response body
// was otherwise invalid.
export class Directus {
   static FormatResponse<T>(response: BaseRestResponse<T>): DirectusResponse<T> {
      ILog.v("Directus.FormatResponse", { response });
      return {
         data: response?.data,
         meta: response?.meta
      };
   }

   static async get<T>({ token, endpoint, queryParameters, id, next, expectedKeys, baseURL, timeout, headers }: DirectusGetParams): Promise<DirectusResponse<T>> {
      if (baseURL) WarnServerMisuedDirectusRest({ endpoint, baseURL, method: "get", params: { id, queryParameters } });
      return BaseRest.get<BaseRestResponse<T>>({
         token,
         baseURL: baseURL || Endpoints.BaseURL,
         endpoint,
         queryParameters,
         additionalHeaders: {
            ...headers,
            "Content-Type": "application/json"
            // "Access-Control-Allow-Origin": PublicEnv.NodeEnv === "development" ? "*" : "no-cors"
         },
         id,
         next,
         expectedKeys: Types.wrapExpectedKeys({
            expectedKeys,
            wrapKey: "data"
         }),
         timeout
      })
         .then((response) => this.FormatResponse(response))
         .catch(({ res, data }) => {
            WarnServerMisuedDirectusRest({ endpoint, status: res?.status, params: { id, queryParameters }, method: "get", debugData: data?.debugData });
            throw `${res?.status} ${res?.statusText}`;
         });
   }

   // Should only pass D for Flows where the request payload & response payload differ.
   static async post<T, D = Partial<T>>({ token, endpoint, payload, baseURL, headers, next, bodyExpected, expectedKeys, timeout, formData }: DirectusPostParams<D>): Promise<DirectusResponse<T>> {
      if (baseURL) WarnServerMisuedDirectusRest({ endpoint, baseURL, method: "post", params: payload });
      return BaseRest.post<BaseRestResponse<T>, D>({
         token,
         baseURL: baseURL || Endpoints.BaseURL,
         endpoint,
         payload,
         additionalHeaders: {
            ...headers,
            "Content-Type": "application/json"
            // "Access-Control-Allow-Origin": PublicEnv.NodeEnv === "development" ? "*" : "no-cors"
         },
         next,
         bodyExpected,
         expectedKeys: Types.wrapExpectedKeys({
            expectedKeys,
            wrapKey: "data"
         }),
         timeout,
         formData
      })
         .then((response) => this.FormatResponse(response))

         .catch(({ res, data }) => {
            ILog.v("Directus.post.catch", { res, data });
            WarnServerMisuedDirectusRest({ endpoint, status: res?.status, params: payload, method: "post", debugData: data?.debugData });
            throw `${res?.status} ${res?.statusText}`;
         });
   }
   static async patch<T>({ endpoint, id, payload, token, expectedKeys, baseURL, timeout, next, headers }: DirectusPatchParams<T>): Promise<DirectusResponse<T>> {
      if (baseURL) WarnServerMisuedDirectusRest({ endpoint, baseURL, method: "patch", params: payload });
      return BaseRest.patch<BaseRestResponse<T>, Partial<T>>({
         token,
         baseURL: baseURL || Endpoints.BaseURL,
         additionalHeaders: {
            ...headers,
            "Content-Type": "application/json"
            // "Access-Control-Allow-Origin": PublicEnv.NodeEnv === "development" ? "*" : "no-cors"
         },
         endpoint,
         id,
         payload,
         expectedKeys: Types.wrapExpectedKeys({
            expectedKeys,
            wrapKey: "data"
         }),
         timeout,
         next
      })
         .then((response) => this.FormatResponse(response))

         .catch(({ res, data }) => {
            WarnServerMisuedDirectusRest({ endpoint, status: res?.status, params: payload, method: "patch", debugData: data?.debugData });
            throw `${res?.status} ${res?.statusText}`;
         });
   }
   static async search<T>({ token, endpoint, query, expectedKeys, baseURL, timeout, next, headers }: DirectusSearchParams<T>): Promise<DirectusResponse<T>> {
      ILog.v("Directus.search", { endpoint, query });
      if (baseURL) WarnServerMisuedDirectusRest({ endpoint, baseURL, method: "search", params: query });
      return BaseRest.search<BaseRestResponse<T>, T>({
         token,
         baseURL: baseURL || Endpoints.BaseURL,
         endpoint,
         additionalHeaders: {
            ...headers,
            "Content-Type": "application/json"
            // "Access-Control-Allow-Origin": PublicEnv.NodeEnv === "development" ? "*" : "no-cors"
         },
         query,
         expectedKeys: Types.wrapExpectedKeys({
            expectedKeys,
            wrapKey: "data"
         }),
         timeout,
         next
      })
         .then((response) => this.FormatResponse(response))

         .catch((_res) => {
            const { res, data } = _res;
            ILog.v("Directus.search.catch", { _res, res, data });
            WarnServerMisuedDirectusRest({ endpoint, status: res?.status, params: query, method: "search", debugData: data?.debugData });
            throw `${res?.status} ${res?.statusText}`;
         });
   }

   static async delete<T>({ token, endpoint, id, expectedKeys, baseURL, headers, payload, timeout, next }: DirectusDeleteParams): Promise<DirectusResponse<T>> {
      if (baseURL) WarnServerMisuedDirectusRest({ endpoint, baseURL, method: "delete", params: { id, payload } });
      return BaseRest.delete<BaseRestResponse<T>, null>({
         token,
         baseURL: baseURL || Endpoints.BaseURL,
         endpoint,
         additionalHeaders: {
            ...headers,
            "Content-Type": "application/json"
            // "Access-Control-Allow-Origin": PublicEnv.NodeEnv === "development" ? "*" : "no-cors"
         },
         id,
         expectedKeys: Types.wrapExpectedKeys({
            expectedKeys,
            wrapKey: "data"
         }),
         payload,
         timeout,
         next
      })
         .then((response) => this.FormatResponse(response))

         .catch(({ res, data }) => {
            WarnServerMisuedDirectusRest({ endpoint, status: res?.status, params: { id, payload }, method: "delete", debugData: data?.debugData });
            throw `${res?.status} ${res?.statusText}`;
         });
   }
}
