import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Endpoints } from "app/_lib/global/Endpoints";
import { TAGS } from "./TAGS";

export const apiService = createApi({
   reducerPath: "api",
   baseQuery: fetchBaseQuery({ baseUrl: Endpoints.BaseURL }),
   // baseQuery: fakeBaseQuery<{}>(),
   tagTypes: Object.values(TAGS),
   endpoints: (builder) => ({}),
   invalidationBehavior: "immediately"
   // refetchOnMountOrArgChange: true,
   // refetchOnFocus: true,
   // refetchOnReconnect: true
});
