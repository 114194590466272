import { Query } from "@directus/sdk";

import { Directus } from "app/_lib/directus/DirectusRest";
import { M } from "app/_types/Schema";
import { REST } from "./REST";

export class DirectusNotificationsService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }

   async getOne(args: { query?: Query<M.CustomDirectusTypes, M.DirectusNotifications[]>; token: string | undefined; id?: string } & NextFetchRequestConfig) {
      return REST.getOne<M.DirectusNotifications>({ token: args.token, endpoint: this.endpoint, query: args.query, id: args.id, revalidate: args.revalidate, tags: args.tags });
   }
   async getMany(args: { query: Query<M.CustomDirectusTypes, M.DirectusNotifications[]>; token: string | undefined } & NextFetchRequestConfig) {
      const { data: response } = await Directus.search<M.DirectusNotifications[]>({
         token: args.token,
         endpoint: this.endpoint,
         query: args.query,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async updateById(args: { token: string; id: number; payload: Partial<M.DirectusNotifications> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.patch<M.DirectusNotifications>({
         token: args.token,
         endpoint: this.endpoint,
         id: args.id,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async create(args: { token: string; payload: M.IOmit<M.DirectusNotifications> } & NextFetchRequestConfig) {
      const { data: response } = await Directus.post<M.DirectusNotifications>({
         token: args.token,
         endpoint: this.endpoint,
         payload: args.payload,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      if (!response) throw new Error("Invalid Directus Response.");
      return response;
   }
   async deleteById(args: { token: string; id: string } & NextFetchRequestConfig) {
      const { data: response } = await Directus.delete<M.DirectusNotifications>({
         token: args.token,
         id: args.id,
         endpoint: this.endpoint,
         /* baseURL: this.baseUrl, */
         next: { revalidate: args.revalidate, tags: args.tags }
      });
      return response;
   }
   // public async getAllByUserId(args: { token?: string; userId: string }) {
   //    const query: Query<S.CustomDirectusTypes, S.DirectusNotifications[]> = {
   //       filter: {
   //          recipient: { _eq: args.userId }
   //       } as QueryFilter<S.CustomDirectusTypes, S.DirectusNotifications>
   //    };
   //    const {data: response} = await Directus.search<S.DirectusNotifications[]>({
   //       token: args.token,
   //       endpoint: this.endpoint,
   //       /* baseURL: this.baseUrl, */
   //       query
   //    });
   //  ii   if (!response) throw new Error("Invalid Directus Response.");
   // return response;
   // }
}
