import getConfig from "next/config";
/*
This is required for Playwright to load envs, but it doesnt work in the browser...
import { loadEnvConfig } from "@next/env";
const projectDir = process.cwd();
loadEnvConfig(projectDir);

*/
const config = getConfig();
const { NODE_ENV } = config?.publicRuntimeConfig || { NODE_ENV: "development" };

export class PrivateEnv {
   static AssemblyAIKey = process.env.ASSEMBLY_AI_KEY;
   static Analyze = process.env.ANALYZE;
   static BuildId = process.env.BUILD_ID; // Next provides this

   static DevelopmentApplicationURL = process.env.DEVELOPMENT_APPLICATION_URL;
   static DevelopmentDirectusAPIKey = process.env.DEVELOPMENT_DIRECTUS_API_KEY;
   static DevelopmentDirectusURL = process.env.DEVELOPMENT_DIRECTUS_URL;
   static DevelopmentFlowAuthToken = process.env.DEVELOPMENT_FLOW_AUTH_TOKEN;
   static DirectusAPIKey = process.env.DIRECTUS_API_KEY;
   static DigitalOceanAPIKey = process.env.DIGITAL_OCEAN_API_KEY;
   static DigitalOceanDeleteAPIKey = process.env.DIGITAL_OCEAN_DELETE_API_KEY;
   static DirectusFlowAuthToken = process.env.DIRECTUS_FLOW_AUTH_TOKEN;
   static EmailInviteKeypair = process.env.EMAIL_INVITE_KEYPAIR;
   static LivekitApiKey = process.env.LIVEKIT_API_KEY;
   static LivekitApiSecret = process.env.LIVEKIT_API_SECRET;
   static LivekitEgressTemplateURL = process.env.LIVEKIT_EGRESS_TEMPLATE_URL;
   static NodeEnv = NODE_ENV;
   static OtelExporterOtlpEndpoint = process.env.OTEL_EXPORTER_OTLP_ENDPOINT;
   static PrivateFlowUUIDJson = process.env.PRIVATE_FLOW_UUID_JSON;
   static ProductionApplicationURL = process.env.PRODUCTION_APPLICATION_URL;
   static ProductionDirectusURL = process.env.PRODUCTION_DIRECTUS_URL;
   static ProductionFlowAuthToken = process.env.PRODUCTION_FLOW_AUTH_TOKEN;
   static S3AccessKeyId = process.env.S3_ACCESS_KEY_ID;
   static S3AccessKeySecret = process.env.S3_ACCESS_KEY_SECRET;
   static S3BucketName = process.env.S3_BUCKET_NAME;
   static S3OriginURL = process.env.S3_ORIGIN_URL;
   static S3Region = process.env.S3_REGION;
   static Secret = process.env.SECRET;
   static SendgridApiKey = process.env.EMAIL_SENDGRID_API_KEY;
   static StagingApplicationURL = process.env.STAGING_APPLICATION_URL;
   static StagingDirectusURL = process.env.STAGING_DIRECTUS_URL;
   static StagingFlowAuthToken = process.env.STAGING_FLOW_AUTH_TOKEN;
   static stripeCampaignPriceIDArray = process.env.STRIPE_CAMPAIGN_PRICE_ID_ARRAY;
   static stripeSecretKey = process.env.STRIPE_SECRET_KEY;
   static stripeWebhookSecretCustomerSetupIntentEvent = process.env.STRIPE_WEBHOOK_SECRET_CUSTOMER_SETUPINTENT_EVENT;
   static stripeWebhookSecretCustomerSubscriptionEvent = process.env.STRIPE_WEBHOOK_SECRET_CUSTOMER_SUBSCRIPTION_EVENT;
   static stripeWebhookSecretInvoiceEvent = process.env.STRIPE_WEBHOOK_SECRET_INVOICE_EVENT;
   static SupportEmailFrom = process.env.SUPPORT_EMAIL_FROM;
   static SupportEmailTo = process.env.SUPPORT_EMAIL_TO;
}
