import { Query, QueryFields, QueryFilter } from "@directus/sdk";
import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { TAGS } from "../TAGS";

export function calculateCDUQueryDefaults({
   userId,
   campaignId,
   roleType
}: {
   userId: string | undefined;
   campaignId: string | undefined;
   roleType: M.CampaignDirectusUser["campaign_directus_user_role"];
}) {
   const query: Query<M.CustomDirectusTypes, M.CampaignDirectusUser[]> = {
      limit: 1,
      filter: {
         _and: [
            { campaign_directus_user_campaign: { _eq: campaignId } },
            { campaign_directus_user_role: { _eq: roleType } },
            { campaign_directus_user_user: { _eq: userId } },
            { _or: [{ campaign_directus_user_invitation: { _eq: "approved" } }, { campaign_directus_user_application: { _eq: "approved" } }] },
            { campaign_directus_user_void: { _neq: true } }
         ]
      } satisfies QueryFilter<M.CustomDirectusTypes, M.CampaignDirectusUser[]>,
      fields: ["*"] satisfies QueryFields<M.CustomDirectusTypes, M.CampaignDirectusUser[]>
   };
   return query;
}

export function calculateCCQueryDefaults({
   userId,
   campaignId,
   roleType,
   teamId,
   attemptId,
   skillsetId
}: {
   userId: string;
   campaignId: string;
   roleType: M.CommitmentConnection["commitment_connection_type"];
   teamId?: string | undefined;
   attemptId?: M.ActivityAttempt["id"] | undefined;
   skillsetId?: string | undefined;
}) {
   let query: Query<M.CustomDirectusTypes, M.CommitmentConnection[]> = {
      limit: 1,
      filter: {
         _and: [
            { commitment_connection_campaign: { _eq: campaignId } },
            { commitment_connection_type: { _eq: roleType } },
            { commitment_connection_directus_users: { _eq: userId } },
            { _or: [{ commitment_connection_invitation: { _eq: "approved" } }, { commitment_connection_application: { _eq: "approved" } }] },
            { commitment_connection_void: { _neq: true } }
         ]
      } satisfies QueryFilter<M.CustomDirectusTypes, M.CommitmentConnection[]>,
      fields: ["*"] satisfies QueryFields<M.CustomDirectusTypes, M.CommitmentConnection[]>
   };
   if (skillsetId) {
      //@ts-expect-error
      query!.filter!._and!.push({ commitment_connection_qualification_set_control: { _eq: skillsetId } });
   } else if (teamId) {
      //@ts-expect-error
      query!.filter!._and!.push({ commitment_connection_activity_control: { _eq: teamId } });
   }
   if (attemptId) {
      //@ts-expect-error
      query!.filter!._and!.push({ commitment_connection_activity_attempt: { _eq: attemptId } });
   }
   ILog.v("calculateCCQueryDefaults", { userId, campaignId, roleType, teamId, attemptId, skillsetId, query });
   return query;
}
export function handleRTKError({ error, endpoint, query }: { error: any; endpoint: string; query?: any }): { error: { status: "CUSTOM_ERROR"; error: string } } {
   ILog.e("handleRTKError", { endpoint, query: query ?? "none_provided", error, requestId: query });

   return { error: { status: "CUSTOM_ERROR", error: error.message } };
}

// export type RTKQueryType<Q> = { query: Query<S.CustomDirectusTypes, unknown>; role?: API.Roles.Queries };

type TagSelectorType = {
   idKey?: string;
   keyIsArray?: boolean; // there may not be an id key in id arrays.
   collectionTag: string;
   includeList?: boolean;
};

type TagType = { type: string; id: string | number };

export function handleRTKTags<R, E, Q>({
   result,
   error,
   query,
   resultTags,
   tagSelectors,
   includeRoleIdTags
}: {
   result: R;
   error: E;
   query: Q;
   // Considerations: Add isSingleton options. Add option to use query[idKey] instead of result[idKey]. Add listOnly option.
   resultTags?: { type: string; id?: string | number }[] | undefined | null;
   tagSelectors?: TagSelectorType[];
   includeRoleIdTags?: boolean;
}) {
   let tags = resultTags ? [...resultTags] : [];

   tagSelectors?.forEach((selector) => {
      if (selector.includeList) {
         tags.push({ type: selector.collectionTag, id: "LIST" });
      }
   });

   if (Array.isArray(result) && result.length > 0) {
      result.forEach((r) => {
         // TODO HP
         const objTags = [] as TagType[];
         tagSelectors?.forEach((selector) => {
            const id = selector.keyIsArray ? r[selector.idKey || "id"].join(",") : r[selector.idKey || "id"];
            if (id) objTags.push({ type: selector.collectionTag, id });
            if (selector.includeList) {
               objTags.push({ type: selector.collectionTag, id: "LIST" });
            }
         });
         tags.push(...objTags);
      });
   } else if (result) {
      const objTags = [] as TagType[];
      tagSelectors?.forEach((selector) => {
         //@ts-expect-error
         const id = selector.keyIsArray ? result[selector.idKey || "id"].join(",") : result[selector.idKey || "id"];
         if (id) objTags.push({ type: selector.collectionTag, id });
         if (selector.includeList) {
            objTags.push({ type: selector.collectionTag, id: "LIST" });
         }
      });
      tags.push(...objTags);
   } else if (error) {
      const ErrorTag = calculateErrorTag({ error });

      // const id = calculateSessionErrorIdentifier({ role: query?.query?.role });
      tags.push({ type: ErrorTag, id: "LIST" });
   }
   //@ts-expect-error
   if (includeRoleIdTags && result && query?.role?.roleId) {
      //@ts-expect-error
      tags.push({ type: TAGS.SESSION_BY_ROLE_ID, id: query?.role?.roleId });
      //@ts-expect-error
   } else if (includeRoleIdTags && error && query?.role?.roleId) {
      //@ts-expect-error
      tags.push({ type: TAGS.SESSION_BY_ROLE_ID_ERROR, id: query?.role?.roleId });
   }

   // Remove duplicates. No object will have the same type AND id.
   let tagStrings = new Set();
   tags = tags.filter((tag) => {
      let key = `${tag.type}-${tag.id}`;
      if (!tagStrings.has(key)) {
         tagStrings.add(key);
         return true;
      }
      return false;
   });

   tags.find((t) => t.type === TAGS.ACTIVITY_CONTROL) && ILog.v("handleRTKTags - result, tags", { ...tags });

   return tags;
}

export function calculateErrorTag({ error }: { error: any }) {
   const errorMessage = error?.error;

   ILog.v("calculateErrorTag", { error });
   if (!errorMessage || errorMessage === null || typeof errorMessage !== "string") {
      return TAGS.UNKNOWN_ERROR;
   }

   if (errorMessage.includes("AUTHENTICATED_ERROR")) {
      return TAGS.AUTHENTICATED_ERROR;
   }
   if (errorMessage.includes("CC_ERROR")) {
      return TAGS.CC_ERROR;
   }
   if (errorMessage.includes("CDU_ERROR")) {
      return TAGS.CDU_ERROR;
   }
   return TAGS.UNKNOWN_ERROR;
}

export function calculateSessionErrorIdentifier({ role }: { role: PA.RoleQueries | undefined }) {
   //    ILog.verbose( "calculateSessionId", { role });
   if (!role) {
   }
   return "LIST";
}
