export const TAGS = {
   CAMPAIGN: "campaign",
   DOCUMENT: "document",
   SECTION_DOCUMENT_EDIT: "section_document_edit",
   FOLDER: "folder",
   SPACE: "space",
   ACTIVITY_CONTROL: "activity_control",
   ACTIVITY_VERSION: "activity_version",
   ACTIVITY_ATTEMPT: "activity_attempt",
   COMMITMENT_CONNECTION: "commitment_connection",
   QUALIFICATION_SET_REQUIREMENT: "qualification_set_requirement",
   QUALIFICATION_SET_ATTEMPT: "qualification_set_attempt",
   QUALIFICATION_SET_VERSION: "qualification_set_version",
   QUALIFICATION_SET_CONTROL: "qualification_set_control",
   DIRECTUS_USERS: "directus_users",
   CAMPAIGN_DIRECTUS_USER: "campaign_directus_user",
   CAMPAIGN_EDITION: "campaign_edition",
   SECTION_ASSET: "section_asset",
   SECTION_ASSET_ERROR: "SECTION_ASSET_ERROR",
   DASHBOARD_CONTROL: "dashboard_control",
   DASHBOARD_VERSION: "dashboard_version",

   SECTION: "section",
   CAMPAIGN_SESSION: "CAMPAIGN_SESSION",
   MEETING: "meeting",
   MEETING_CONNECTION: "meeting_connection",
   ACTIVITY_SESSION: "ACTIVITY_SESSION",
   ACCOUNT_CUSTOMER: "ACCOUNT_CUSTOMER",
   CHECKOUT_INVOICE: "CHECKOUT_INVOICE",
   DIRECTUS_NOTIFICATIONS: "directus_notifications",
   ANY_SESSION: "ANY_SESSION",
   CDU_SESSION: "CDU_SESSION",
   CDU_ERROR: "CDU_ERROR",
   CC_SESSION: "CC_SESSION",
   CC_ERROR: "CC_ERROR",
   AUTHENTICATED_ERROR: "AUTHENTICATED_ERROR",
   UNKNOWN_ERROR: "UNKNOWN_ERROR",
   AUTHENTICATED_SESSION: "AUTHENTICATED_SESSION",
   SESSION_BY_ROLE_ID: "SESSION_BY_ROLE_ID",
   SESSION_BY_ROLE_ID_ERROR: "SESSION_BY_ROLE_ID_ERROR",
   CHAT: "CHAT",
   CHAT_MESSAGE: "CHAT_MESSAGE"
};
