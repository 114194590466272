import ILog from "../global/Log";

export const Types = {
   definedString: (value: unknown) => {
      if (typeof value === "string" && value !== undefined && value !== null && value !== "") {
         return value;
      } else {
         return undefined;
      }
   },

   definedStringStrict: (value: unknown) => {
      // The same as definedString, but also checks for "undefined" and "null" strings. Mostly useful for evaluating url params.
      if (typeof value === "string" && value !== undefined && value !== null && value !== "" && value !== "undefined" && value !== "null") {
         return value;
      } else {
         return undefined;
      }
   },

   // When allowEmpty = false, the array cannot be empty. Default is true.
   definedArray: (value: unknown, options?: { allowEmpty: boolean }) => {
      if (Array.isArray(value) && (value.length > 0 || options?.allowEmpty !== false)) {
         return value as unknown[];
      } else {
         return undefined;
      }
   },

   definedStringArray: (value: unknown, options?: { allowEmpty: boolean }) => {
      if (Array.isArray(value) && (value.length > 0 || options?.allowEmpty !== false)) {
         for (let i = 0; i < value.length; i++) {
            if (typeof value[i] !== "string") {
               return undefined;
            }
            if (i === value.length - 1) {
               return value as string[];
            }
         }
      } else {
         return undefined;
      }
   },

   definedErrorStatus: (err: unknown) => {
      return typeof err === "object" && err !== null && "status" in err ? err.status : err;
   },

   // Providing keys[] is a more accurate/strict method of checking the object. It will ensure any required keys are defined.
   definedObject: <T>(value: unknown, keys?: string[]) => {
      if (typeof value === "object" && value !== undefined && value !== null && !Array.isArray(value)) {
         if (keys === undefined || keys.length === 0) {
            // Less ideal, but still works. Only garuntees that value is an object, not it's contents
            return value as T;
         }
         let valueObject = value as Record<string, unknown>;

         // If a key has a . in it, it's a nested key. We need to check for nested keys.

         for (let i = 0; i < keys.length; i++) {
            // Better, garuntees that value is an object and has the expected keys
            const checkKeys = keys[i].split(".");
            if (checkKeys.length === 1) {
               const k = checkKeys[0];
               if (!(k in value) || !valueObject[k] === undefined) {
                  return undefined;
               }
            } else {
               // This checks for nested keys. You can do as deep as needed, but keep performance in mind.
               let v = valueObject;
               for (let i = 0; i < checkKeys.length; i++) {
                  const k = checkKeys[i];
                  if (!(k in v) || v[k] === undefined) {
                     return undefined;
                  }
                  // These type casts aren't ideal, but they are ok (They are true). They avoid implicity any.
                  v = v[k] as Record<string, unknown>;
               }
               return value as T;
            }

            if (i === keys.length - 1) {
               return value as T;
            }
         }
      }
   },

   wrapExpectedKeys: ({ expectedKeys, wrapKey }: { expectedKeys?: string[]; wrapKey?: string }) => {
      if (expectedKeys === undefined || expectedKeys.length === 0) {
         return expectedKeys;
      }
      const keys = expectedKeys.map((k) => `${wrapKey}.${k}`);
      return keys;
   },

   definedunknownRespData: async <T>(
      resp: Response,
      // Providing expectedKeys[] gives a higher level of certainty that the response is what we expect.
      expectedKeys: string[] = []
   ): Promise<{
      resolve: boolean;
      value: T | undefined;
      debugData?: any; // This is the resp.json(), regardless of if the request was successful. This is useful for debugging only.
   }> => {
      if (resp !== undefined && resp !== null && typeof resp === "object" && resp.status.toString().startsWith("2")) {
         const contentType = resp.headers.get("content-type");
         ILog.v("Types.definedunknownRespData", { contentType });
         const data: unknown = contentType && contentType.indexOf("application/json") !== -1 ? await resp.json() : await resp.text();

         if (data === undefined || data === null || data === "") {
            // But there is no data payload. Resolve, but return undefined. This is the case for all 204's.
            return {
               resolve: true,
               value: undefined
            };
         } else if (typeof data === "object") {
            // There is a response.data object. Check for the expected keys.
            const definedObjectData = Types.definedObject<T>(data, expectedKeys);
            if (definedObjectData !== undefined && definedObjectData !== null) {
               // All keys are defined. Return the data.
               return {
                  resolve: true,
                  value: definedObjectData // T because of [dataKey]: T
               };
            } else {
               // One or more keys were undefined. Return undefined.
               return {
                  resolve: false,
                  value: undefined,
                  debugData: data
               };
            }
         } else if (typeof data === "string") {
            return {
               resolve: true,
               value: data as T, // This assumes T is string in this case. This is a safe assumption.
               debugData: data
            };
         } else {
            // The response was not an object or string. Return undefined.
            return {
               resolve: false,
               value: undefined,
               debugData: data
            };
         }
      } else {
         // The request was not a success. Return undefined
         return {
            resolve: false,
            value: undefined,
            debugData: "Request was not successful."
         };
      }
   }
};
