import { DirectusJWTPayload } from "app/_types/Session/DirectusJWTPayload";

export function decodeJWT(key: string): DirectusJWTPayload {
   try {
      const [signature, payload] = key.split(".");
      if (!!signature && !payload) {
         return {
            iat: 0,
            exp: 0,
            role: "admin",
            iss: "",
            admin_access: true,
            app_access: true,
            id: ""
         };
      }
      const decodedPayload = atob(payload);
      return JSON.parse(decodedPayload);
   } catch {
      // ILog.error("decodeJWT", { key });
      // throw new Error("Invalid JWT");
      return {
         iat: 0,
         exp: 0,
         role: "admin",
         iss: "",
         admin_access: true,
         app_access: true,
         id: ""
      };
   }
}
