import { Directus } from "app/_lib/directus/DirectusRest";
import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import { Sessions } from "app/_types/Session";
import { DateTime } from "luxon";

export class ActivitySessionService {
   // private baseUrl: string;
   private endpoint: string;

   constructor(private args: { /* baseUrl: string; */ endpoint: string }) {
      // this.baseUrl = args.baseUrl;
      this.endpoint = args.endpoint;
   }

   async createSession(args: { token: string; payload: PA.Flows.Session.GetActivitySession["Req"] } & NextFetchRequestConfig): Promise<Sessions.Activities.Session> {
      const { data: res } = await Directus.post<PA.Flows.Session.GetActivitySession["Req"]>({
         endpoint: this.endpoint,
         token: args.token,
         payload: {
            commitment_connection: args.payload?.commitment_connection,
            role_type: args.payload.role_type
         },
         next: {
            revalidate: args.revalidate,
            tags: args.tags
         }
      });
      const preNormalizedSession = res as unknown as PA.Flows.Session.GetActivitySession["Res"];
      ILog.v("ActivitySessionService_createSession", { preNormalizedSession, res });
      const session = { ...preNormalizedSession, expires: DateTime.fromISO(preNormalizedSession?.expires).toMillis() } as Sessions.Activities.Session;

      return session;
   }
}
